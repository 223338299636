import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Intro from "../components/intro";
import TextImagegrid from "../components/text-imagegrid";

const IndexPage = () => (
  <Layout>
    <SEO title="o'mellis Premium Juices & Smoothies" />
    <Intro
      headline="Fruit Flash"
      image={require("../images/neu/frau_hut-titelbild.jpg")}
    >
      <p>
        Ob in der Arbeit, beim Unterricht, unterwegs im Auto oder Zug und beim
        Sport – genieße volle Frische jederzeit. Mit o’mellis – dem Original
        seit 1983.
      </p>
    </Intro>
    <TextImagegrid
      headline="Unfassbar <br>fruchtig"
      link={{ url: "/ueber-uns/", label: "Mehr über uns" }}
      images={[
        require("../images/neu/shop_1.jpg"),
        require("../images/neu/maenner_smoothies.jpg"),
        require("../images/neu/smoothie_rot.jpg"),
        require("../images/neu/smoothies_gelb.jpg")
      ]}
      backgroundDark={true}
    >
      <p>
        Unfassbar fruchtig - Erntefrisches Obst und knackiges Gemüse vor deinen
        Augen gepresst, gemixt und püriert: das ist o´mellis, das ist Fast Fruit
        statt Fast Food!
        <br />
      </p>
    </TextImagegrid>
    <TextImagegrid
      headline="Einfach <br>kostbar"
      reverse={true}
      link={{ url: "/einfach-kostbar/", label: "Mehr erfahren" }}
      images={[require("../images/neu/kind-wassermelone.jpg")]}
      centerVertically
    >
      <img
        src={require("../images/icons/repet_logo.png")}
        alt="Wir sind rePET"
        className="repet-logo"
      />
      <p>
        Seit 1983 steht o’mellis für unfassbar fruchtige Smoothies und Juices
        mit erntefrischem Obst und knackigem Gemüse. Uns liegt aber nicht nur
        die Zufriedenheit unserer Kundinnen und Kunden sehr am Herzen, sondern
        auch die Umwelt. Denn diese ist das kostbarste Gut, das wir uns alle
        teilen. Deshalb achten wir bei unseren Zutaten auf eine regionale
        Herkunft und bei exotischen Früchten auf Nachhaltigkeit. Viele Kundinnen
        und Kunden sprechen uns aber auch auf unsere Verpackungen bzw. Becher an
        und warum wir Plastik als Material wählen. Das möchten wir dir hier
        näher erklären.
        <br />
      </p>
    </TextImagegrid>
    <TextImagegrid
      backgroundDark={true}
      headline="Wo du uns <br>findest"
      link={{ url: "/standorte/", label: "Unsere Standorte" }}
      images={[require("../images/neu/standorte-teaser.jpg")]}
      className="locations-teaser"
      wideImage={true}
    />
    <TextImagegrid
      reverse
      headline="Jetzt o’mellis Stamm&shy;kundenkarte holen und jeden 11. Smoothie GRATIS genießen!"
      images={[require("../images/neu/stammkundenkarte.jpg")]}
      // wideImage={true}
    >
      <p>
        Wenn du die Stammkundenkarte vollständig ausgefüllt bei uns abgibst,
        nimmst du an der monatlichen Verlosung toller Preise teil.
      </p>
    </TextImagegrid>
  </Layout>
);

export default IndexPage;
